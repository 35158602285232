<template>
    <div class="app-modal modal fade" id="infoSeriaModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered overflow-hidden info-modal-content">
        <div class="modal-content  position-relative info-modal-content">
          <div class="widget-tracker widget-tracker-active d-flex flex-column align-items-center" id="password-widget-tracker">
            <div class="app-modal-header border-0">
              <div class="modal-title h5 text-main fw-bold info-modal-title">
                <span>Seriya nömrəsi barədə məlumat</span>
                <img data-bs-dismiss="modal" src="@/assets/images/info-remove.svg" />
              </div>
            </div>
           <div class="px-3">
            <div class="passport-img">
              <img src="@/assets/images/passport_seria_new.png" />
            </div>
            <div class="passport-img mb-4">
              <img src="@/assets/images/passport_seria_old.png" />
            </div>
           </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { Modal } from "bootstrap";
  
  export default {
    data: () => ({
      modal: null,
    }),
    mounted() {
      this.modal = new Modal(document.getElementById("infoSeriaModal"));
    },
    methods: {
      show() {
        this.modal.show();
      },
    },
  };
  </script>