<template>
  <div class="pb-2  col-xs-12 col-sm-12 col-md-12">
    <p class="h4 initial-form-text ">
      Zəhmət olmasa aşağıdakı qeyd olunan şirkətlərdən birini seçin.
    </p>
    <div class="d-flex align-items-center mt-4 mb-3">
      <div class="check-info-content-body-head">
        Sığorta haqqı :
      </div>
      <div class="check-info-content-body-text ms-2">
        {{ premium }} <span>AZN</span>
      </div>
    </div>
  </div>
    <div class="row  mx-0" v-for="d in data" v-bind:key="d.uid">
      <div class="text-decoration-none d-flex insurance-company-item">
        <div class="d-flex align-items-center">
          <img :src="d.logo" style="height: 50px;" />
          <p class="mb-0 ms-2">{{ d.name }}</p>
        </div>
        <button type="button" class="btn-main btn-default btn-hover py-1 ms-auto ins-company-name" :disabled="loading"
          @click.prevent="select(d.uid,d.name)">

          <span class="spinner-border spinner-border-sm " v-if="loading && selectedCompanyId == d.uid" />
          <span class="ins-company-name-text">Seç</span>
        </button>
      </div>
    </div>

  <div class="row mt-3">
    <div class="col d-flex justify-content-end">
      <a @click="back" class="btn  btn-default btn-hover d-inline-block text-decoration-none">
        Geri
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data", "premium", "loading"],
  data: () => ({
    selectedCompanyId: null,
    selectedCompanyName:null
  }),
  methods: {
    select(companyId,companyName) {
      this.selectedCompanyId = companyId;
      this.selectedCompanyName = companyName;
      this.$emit("company-selected", companyId,companyName);
    },

    back() {
      this.$emit("back");
    },
  },
};
</script>