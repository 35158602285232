<template>
    <div>
        <div class="pb-5">
            <p class="h4 initial-form-text">
                Şəhadətnamənizin uğurla buraxılması üçün məlumatın doğruluğunu yoxlayın.
            </p>
        </div>
        <div v-if="data" class="row">
            <div class="check-info-body">
                <div class="check-info-body-content">
                    <div>
                        <div class="check-info-body-content-head">İcbari sığorta müqavilə barədə məlumat</div>
                        <div class="row check-info-content-body">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 py-2">
                                <div class="check-info-content-body-head">Sığorta şirkəti</div>
                                <div class="check-info-content-body-text">
                                    {{ data.companyName }}
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 py-2">
                                <div class="check-info-content-body-head">Sığorta haqqı</div>
                                <div class="check-info-content-body-text">
                                    {{ premium }} <span>AZN</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="check-info-body-content">
                    <div>
                        <div class="check-info-body-content-head">Nəqliyyat vasitəsi barədə məlumat</div>
                        <div class="row check-info-content-body">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 py-2">
                                <div class="check-info-content-body-head">NV qeydiyyat nişanı</div>
                                <div class="check-info-content-body-text">
                                    {{ data.carNumber }}
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 py-2">
                                <div class="check-info-content-body-head">Şəhadətnamə No</div>
                                <div class="check-info-content-body-text">
                                    {{ data.certificateNumber }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="check-info-body-content">
                    <div>
                        <div class="check-info-body-content-head">Şəxs barədə məlumat</div>
                        <div class="row check-info-content-body">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 py-2">
                                <div class="check-info-content-body-head">Ş/V FİN kod</div>
                                <div class="check-info-content-body-text">
                                    {{ data.pin }}
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 py-2">
                                <div class="check-info-content-body-head">Ş/V Seriya və nömrəsi</div>
                                <div class="check-info-content-body-text">
                                   {{ data.idSerial }}
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 py-2">
                                <div class="check-info-content-body-head">Mobil nömrə</div>
                                <div class="check-info-content-body-text">
                                    {{ data.phoneNumber }}
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 py-2">
                                <div class="check-info-content-body-head">Sürücülük vəsiqəsi</div>
                                <div class="check-info-content-body-text">
                                   {{ data.licenseNumber }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="d-flex justify-content-end pe-0">
                        <a @click="back" class="btn  btn-default btn-hover d-inline-block text-decoration-none">
                            Geri
                        </a>
                        <button @click="accept" type="submit" class="btn-main btn-default btn-hover cursor-pointer d-inline-block ms-3"
                            :disabled="loading">
                            <span class="spinner-border spinner-border-sm" v-if="loading" />
                            Təsdiqlə
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    props: ["data","loading"],
    methods: {
        back() {
            this.$emit("back");
        },
        accept() {
            this.$emit("confirm");
        },
    },
};
</script>