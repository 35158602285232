<template>
  <div class="app-modal modal fade" id="errorModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered overflow-hidden">
      <div class="modal-content  position-relative">
        <div class="app-modal-body widget-tracker widget-tracker-active">
          <div class="app-modal-header mt-4 border-0 text-center">
            <p class="error-modal-title my-2">Daxil edilən məlumatlar <span class="text-blue">doğru</span> deyil</p>
            <p class="text-gray ">
              Zəhmət olmasa, məlumatları yenidən yoxlayın və əməliyyatı təkrar cəhd edin. 
            </p>
            <button type="button" class="app-modal-close" data-bs-dismiss="modal"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { Modal } from "bootstrap";

export default {
  data: () => ({
    modal: null,
  }),
  mounted() {
    this.modal = new Modal(document.getElementById("errorModal"));
  },
  methods: {
    show() {
      this.modal.show();
    },
  },
};
</script>