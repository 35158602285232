<template>
  <div class="row">
    <p class="h4 initial-form-text">
      Zəhmət olmasa aşağıdakı formu dolduraraq davam edin.
    </p>
    <form @submit.prevent="submitHandler">
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group position-relative mt-5" :class="{ error: v$.form.carNumber.$errors.length }">
            <input type="text" class="form-control text-uppercase" v-model="form.carNumber" placeholder="10AA100" />
            <label class="floating-label small">Dövlət qeydiyyat nişanı*</label>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group position-relative mt-5" :class="{ error: v$.form.certificateNumber.$errors.length }">
            <input type="text" class="form-control text-uppercase" v-model="form.certificateNumber" placeholder="AA123456" />
            <label class="floating-label small">
              Avtomobil şəhadətnamə nömrəsi*
            </label>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-lg-6">
          <div class="form-group position-relative mt-5" :class="{ error: v$.form.pin.$errors.length }">
            <input type="text" class="form-control" v-model="form.pin" placeholder="0000000"/>
            <label class="floating-label small">Ş/V FİN kod*</label>
            <img @click="showFin" src="@/assets/images/ins-info-icon.svg" class="ins-info-icon" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group position-relative mt-5">
            <label class="small input-group-label" :class="{ 'full': seria && number }">Ş/V seriyası və nömrəsi*</label>
            <div class="form-control-inputs">
              <input maxlength="3" type="text" class="form-control" v-model="seria" placeholder="Seriya">
              <input type="text" class="form-control" v-model="number" placeholder="12345678">
            </div>
            <img @click="showSeria" src="@/assets/images/ins-info-icon.svg" class="ins-info-icon" />
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-lg-6">
          <div class="form-group position-relative mt-5" :class="{ error: v$.form.phoneNumber.$errors.length }">
            <input type="text" class="form-control" v-model="form.phoneNumber" v-maska="'+994## ###-##-##'"
              placeholder="+994 ( __ ) ___ __ __" />
            <span class="floating-label small">Mob. Telefon*</span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group position-relative mt-5 mb-3">
            <label class="small input-group-label" :class="{ 'full': carSeria && carNumber }">Sürücülük vəsiqəsi</label>
            <div class="form-control-inputs">
              <input maxlength="3" type="text" class="form-control" v-model="carSeria" placeholder="Seriya"
                :disabled="!form.hasLicenseId">
              <input type="text" class="form-control" v-model="carNumber" placeholder="12345678"
                :disabled="!form.hasLicenseId">
            </div>
            <img @click="showLicense" src="@/assets/images/ins-info-icon.svg" class="ins-info-icon" />
          </div>
          <div class="d-flex">
            <label class="d-flex align-items-center">
              <input type="radio" name="check" v-model="form.hasLicenseId" :value="true" @click="form.hasLicenseId = true"> <span class="ms-2"> Var</span>
            </label>
            <label class="ms-4 d-flex align-items-center">
              <input type="radio" name="check" v-model="form.hasLicenseId" :value="false" :checked="!form.hasLicenseId"
              @click="hasLicense">
                <span class="ms-2"> Yoxdur</span> 
            </label>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="request-form-btn d-flex justify-content-end">
          <a @click="cancel" class="btn  btn-default btn-hover d-inline-block text-decoration-none">
            Imtina et
          </a>
          <button type="submit" class="btn-main btn-default btn-hover cursor-pointer d-inline-block ms-3"
            :disabled="loading">
            <span class="spinner-border spinner-border-sm" v-if="loading" />
            Davam et
          </button>
        </div>
      </div>
    </form>
    <info-fin-modal ref="infoFinModal" />
    <info-seria-modal ref="infoSeriaModal" />
    <info-license-modal ref="infoLicenseModal" />
  </div>
</template>

<script>
import { maska } from "maska";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import InfoFinModal from './InfoFinModal.vue';
import InfoSeriaModal from './InfoSeriaModal.vue';
import InfoLicenseModal from './InfoLicenseModal.vue';

const initialValue = {
  carNumber: null,
  certificateNumber: null,
  pin: null,
  idSerial: null,
  phoneNumber: null,
  hasLicenseId: false,
  licenseNumber: null
};

const validations = {
  carNumber: { required },
  certificateNumber: { required },
  pin: { required },
  phoneNumber: { required },
};

export default {
  props: ["loading"],
  directives: { maska },
  components: {
    InfoFinModal,
    InfoSeriaModal,
    InfoLicenseModal
  },
  data: () => ({
    form: { ...initialValue },
    seria: null,
    number: null,
    carSeria: null,
    carNumber: null,

  }),
  validations: () => ({
    form: { ...validations },
  }),
  setup: () => ({ v$: useVuelidate() }),
  mounted () {
    const params = this.$route.query;
    this.form.carNumber = params.carNumberquery;
    this.form.certificateNumber = params.certificateNumber;
    this.form.pin=params.pin;
    this.form.phoneNumber=params.phone;
    this.number=params.number
    this.seria=params.seria
    this.carNumber=params.carNumber
    this.carSeria=params.carSeria
    if (params.carNumber!=null) {
      this.form.hasLicenseId=true
    }
  },
  methods: {
    async submitHandler() {
      const result = await this.v$.$validate();
      if (this.seria && this.number) {
        this.form.idSerial = this.seria + this.number
      } else {
        this.form.idSerial = null
      }
      if (this.carSeria && this.carNumber) {
        this.form.licenseNumber = this.carSeria + this.carNumber
      } else {
        this.form.licenseNumber = null
      }

      if (result) {
        this.$emit("success", this.form);
      }
    },
    hasLicense(){
      this.form.hasLicenseId = false;
      this.carSeria=null;
      this.carNumber=null
    },
    cancel() {
      this.form = initialValue;
      this.seria= null,
      this.number= null,
      this.carSeria= null,
      this.carNumber= null
    },
    showSeria() {
      this.$refs["infoSeriaModal"].show();
    },
    showFin() {
      this.$refs["infoFinModal"].show();
    },
    showLicense() {
      this.$refs["infoLicenseModal"].show();
    },
  },
  // watch: {
  //   seria(value) {
  //     if (value && this.number) {
  //       this.form.idSerial = value + this.number
  //     } else {
  //       this.form.idSerial = null
  //     }
  //   },
  //   number(value) {
  //     if (this.seria && value) {
  //       this.form.idSerial = this.seria + value
  //     } else {
  //       this.form.idSerial = null
  //     }   
  //   },
  //   carSeria(value) {
  //     if (value && this.carNumber) {
  //       this.form.licenseNumber = value + this.carNumber
  //     } else {
  //       this.form.licenseNumber = null
  //     }
  //   },
  //   carNumber(value) {
  //     if (this.carSeria && value) {
  //       this.form.licenseNumber = this.carSeria + value
  //     } else {
  //       this.form.licenseNumber = null
  //     } 
  //   }
  // }
};
</script>